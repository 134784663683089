// --------------------------
// Global page structure
// --------------------------
html,
body,
app-root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

body,
app-root,
.main {
  flex-grow: 1;
}

.main {
  display: block;
  max-width: 100%;
  overflow: hidden;
}
