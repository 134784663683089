mat-form-field,
.cdk-overlay-container {
  --color-bg: initial;
  --color-text: initial;
}

// --------------------------
// Select
// --------------------------

.mat-select,
.mat-option {
  font-family: inherit !important;
}

.mat-select {
  font-weight: 500 !important;
}

.mat-form-field-infix {
  width: auto !important;
  border-top: none !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.375em 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  top: 0 !important;
  margin-top: 0 !important;
  padding: 0 16px !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  top: 0 !important;
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: none !important;
}

.mat-form-field-label-wrapper {
  top: 0 !important;
  padding-top: 0 !important;
}

.mat-form-field-label,
.mat-select-placeholder {
  color: inherit !important;
}

.mat-form-field-appearance-outline .mat-form-field-label {
  top: 50% !important;
  transform: translateY(-50%) !important;
  margin-top: 0 !important;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  display: none !important;
}

.mat-select-trigger,
.mat-form-field-type-mat-select .mat-form-field-label {
  height: 1.25em !important;
}

mat-select-trigger {
  display: flex !important;
}

.mat-select-trigger {
  display: flex !important;
  align-items: center;
}

.mat-form-field-appearance-outline .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-gap,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
  display: none !important;
}

.mat-form-field-outline,
.mat-form-field-outline-thick {
  border: 1px solid #1c2463;
}

.mat-form-field-outline,
.mat-form-field-outline-thick {
  border-radius: 16px;
}

.mat-pseudo-checkbox {
  border-radius: 4px !important;
  border-color: #071a39 !important;
}

.mat-pseudo-checkbox.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox.mat-pseudo-checkbox-indeterminate,
.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: var(--jaune) !important;
  border-color: var(--jaune) !important;
}

.mat-pseudo-checkbox-checked::after {
  color: #071a39 !important;
}

.mat-pseudo-checkbox-checked::after {
  top: 2.5px !important;
  left: 1.5px !important;
  border-width: 1px !important;
}

.mat-select-value,
.mat-option {
  color: inherit !important
}

.mat-select-value {
  max-width: 100% !important;
  margin-right: 8px;
}

.mat-option {
  font-size: 0.875rem !important;
}

.multiple-select-number-checked {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.125rem;
  width: 1.125rem;
  margin-left: 8px;
  border-radius: 0.5625rem;
  background: var(--jaune) !important;
  font-size: 0.625rem;
  font-weight: bold;
}

.mat-select-arrow {
  color: inherit !important;
}

.mat-form-field-wrapper{
  padding: 0 !important;
}
// FIN de Select
