// --------------------------
// Visually hide any element (mostly text) accessibly
// --------------------------
.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.scroll-offset-top {
  scroll-margin-top: 120px;
}
