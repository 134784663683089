// LES STYLES CI-DESSOUS NE DOIVENT RESTER ICI QU'À TITRE TEMPORAIRE !

// --------------------------
// Divers
// --------------------------

.text-block {
  p {
    margin-bottom: var(--spacing-l);
  }

  ul,
  ol {
    padding-left: 3ch;
  }
}

.cta__button {
  margin: var(--spacing-s);
}

.step {
  a {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: var(--spacing-l) !important;
    text-decoration: none;

    &[href=""] {
      text-decoration: line-through red;
    }
  }
}

.card-full-width {
  h3 {
    margin: 0 0 var(--spacing-s) !important;
    font-size: 1.125rem;
  }

  p {
    margin: var(--spacing-l) 0 !important;
  }
}

.coming-soon {
  --color-bg: initial;

  position: relative;

  img {
    display: block;
    margin: 0 auto;
    width: 200px;
    transform: translateX(-50px);
  }

  p {
    position: absolute;
    top: 24px;
    left: 51%;
    max-width: 10ch;
    font-family: "IBM Plex Sans Condensed", sans-serif;
    font-size: 1.875rem;
    font-weight: bold;
  }
}

.video {
  margin: 0;

  .video__wrapper {
    position: relative;
    height: 0;
    padding-top: 56.25%;
    border-radius: var(--border-radius-m);
    overflow: hidden;
  }

  video,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  figcaption {
    margin-top: var(--spacing-l);
    font-size: 0.75rem;
    opacity: 0.5;
  }
}

.content-list {
  padding-left: 3ch;
  margin: 32px 0;

  li::marker {
    color: var(--jaune);
    font-weight: bold;
  }
}

.article__position {
  .page-section {
    @media screen and (max-width: 959px) {
      padding-top: 32px;
    }
  }
}

// Supprimer les espaces excédentaires dans la contribution Taleez
body:not(.v2021) {
  p > br:only-child {
    display: none;
  }
}
