// --------------------------
// Styles du design system
// --------------------------
.ds-header {
  overflow: visible !important;
}

.ds-header nav {
  align-items: flex-start;
}

.ds-header nav ul li {
  margin: 0;
}

.ds-header nav ul li a {
  transition: none;
  font-size: inherit;

  &:hover {
    box-shadow: none;
  }
}

.ds-input-group {
  --color-bg: initial;
  --color-text: initial;
}

.ds-input-group__label {
  height: 1.25rem !important;

  .ds-input-group--required & {
    display: flex;
  }

  .ds-input-group--required &::after {
    margin-left: 4px;
    content: "*";
  }
}

.ds-input-group--error {
  --color-text: initial;
  --color-bg: initial;

  .ds-checkbox-group + & {
    margin-top: 8px;
  }
}

.ds-btn {
  display: flex;
  align-items: center;
  height: auto !important;
  min-height: 40px !important;
  text-transform: none;
}

.ds-btn--secondary {
  color: #071a39;
}

a.ds-btn.ds-btn--disabled {
  background-color: rgba(112, 141, 187, 0.33);
  color: rgba(7, 26, 57, 0.5);
  cursor: not-allowed;
  pointer-events: none;
}
