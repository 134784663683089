body:not(.v2021) {
  .main {
    flex-grow: 1;
    display: block;
  }

  // --------------------------
  // sections de page
  // --------------------------
  .page-section {
    position: relative;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: minmax(0, 1fr) minmax(auto, 960px) minmax(0, 1fr);
    grid-template-areas: ". content .";
    padding: 64px;
    overflow: hidden;

    &--dark {
      --color-bg: var(--bleu-primaire);
      --color-text: var(--blanc);
    }

    &--medium {
      --color-bg: var(--bleu-clair);
    }

    &--transparent {
      --color-bg: transparent;
    }

    @media screen and (max-width: 959px) {
      padding: 64px 32px;
    }
  }

  .page-section_partner-space{
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: minmax(0, 50px) minmax(auto, 960px) minmax(0, 1fr);
    grid-template-areas: ". content .";
    padding: 20px 60px;
    overflow: hidden;
    &--dark {
      --color-bg: var(--bleu-primaire);
      --color-text: var(--blanc);
    }

    &--medium {
      --color-bg: var(--bleu-clair);
    }

    &--transparent {
      --color-bg: transparent;
    }
    
    @media screen and (max-width: 959px) {
      grid-template-columns: minmax(0, 30px) minmax(auto, 960px) minmax(0, 1fr);
      padding: 10px 0px;

      h1{
        font-size: 30px;
      }
    }
    }

  .page-section__title {
    position: relative;
    max-width: 43.5rem;
    margin-bottom: 32px;
    padding-bottom: 10px;

    &::after {
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      max-width: 120px;
      border: solid 2px #ffcd00;
      border-radius: 2px;
      background-color: #ffcd00;
      content: "";
    }
  }

  .page-section__title-logo {
    width: 120px;
    margin-right: 32px;

    @media screen and (max-width: 599px) {
      display: none;
    }
  }

  .page-section__content {
    --color-bg: initial;

    grid-area: content;
    position: relative;
    min-width: 0;
    z-index: 10;
  }

  .page-section__background-text {
    --color-bg: transparent;
    --color-text: var(--bleu-primaire);

    position: absolute;
    bottom: -0.32em;
    right: calc(50% - 50vw - 0.11em);
    background: transparent;
    font-family: "IBM Plex Sans Condensed", sans-serif;
    font-size: 400px;
    font-weight: bold;
    pointer-events: none;

    .page-section--dark & {
      --color-text: var(--bleu-clair);

      opacity: 0.08;
    }

    .page-section--medium & {
      --color-text: var(--bleu-primaire);

      opacity: 0.04;
    }

    @media screen and (max-width: 599px) {
      font-size: 170px;
    }
  }

  .page-section__background-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    z-index: 1;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  // --------------------------
  // blocs divers
  // --------------------------

  .ressource {
    display: block;
  }

  .ressource + .ressource {
    margin-top: 20px;
  }

  .yellow-text {
    color: var(--jaune);
  }

  // --------------------------
  // Form fields
  // --------------------------
  .formfield {
    width: 180px;

    &--auto {
      width: auto;
    }

    &--fullwidth {
      width: 100%;
    }
  }
}

// --------------------------
// Grid container and columns
// --------------------------
.grid-container {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: var(--gutter-l);

  .lg-grid-col-12 {
    grid-column-end: span 12;
  }

  .lg-grid-col-9 {
    grid-column-end: span 9;
  }

  .lg-grid-col-8 {
    grid-column-end: span 8;
  }

  .lg-grid-col-7 {
    grid-column-end: span 7;
  }

  .lg-grid-col-6 {
    grid-column-end: span 6;
  }

  .lg-grid-col-5 {
    grid-column-end: span 5;
  }

  .lg-grid-col-4 {
    grid-column-end: span 4;
  }

  .lg-grid-col-3 {
    grid-column-end: span 3;
  }

  .lg-grid-col-2 {
    grid-column-end: span 2;
  }

  // Tablette
  @media screen and (max-width: 959px) {
    grid-template-columns: repeat(8, minmax(0, 1fr));
    gap: var(--gutter-s);

    .md-grid-col-8 {
      grid-column-end: span 8;
    }

    .md-grid-col-6 {
      grid-column-end: span 6;
    }

    .md-grid-col-5 {
      grid-column-end: span 5;
    }

    .md-grid-col-4 {
      grid-column-end: span 4;
    }

    .md-grid-col-2 {
      grid-column-end: span 2;
    }
  }

  // smartphone
  @media screen and (max-width: 599px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));

    .sm-grid-col-4 {
      grid-column-end: span 4;
    }

    .sm-grid-col-3 {
      grid-column-end: span 3;
    }
  }
}
