:root {
  // --------------------------
  // DESIGN TOKENS - colors
  // --------------------------
  // Palette
  --primary-color: #ffcd00;
  --secondary-color: #071a39;
  --secondary-color-lighter: #1c2463;
  --secondary-color-50: #f5f7fb;
  --secondary-color-100: #dde8fb;
  --secondary-color-200: #5273a7;
  --tertiary-color-300: #5a5858;
  --grey-color: #757575;
  --white: #fff;
  --black: #000;

  // Functional colors
  --text-color-body: var(--secondary-color);
  --text-color-title: var(--secondary-color-lighter);
  --background-light: var(--secondary-color-50);
  --background-dark: var(--secondary-color-lighter);
  --interaction-color: var(--primary-color);
  --interaction-color-light: #ffe166;

  // --------------------------
  // DESIGN TOKENS - typography
  // --------------------------
  --font-family-title: 'IBM Plex Sans Condensed', sans-serif;
  --font-family-body: 'IBM Plex Sans', sans-serif;

  --font-size-title-1: 2.375rem; // 38px
  --font-size-title-2: 1.5rem; // 24px
  --font-size-title-3: 1.3125rem; // 21px
  --font-size-body-xl: 1.25rem; // 20px
  --font-size-body-l: 1.125rem; // 18px
  --font-size-body: 1rem; // 16px
  --font-size-body-s: 0.875rem; // 14px
  --font-size-body-s-filter: 0.85rem; // 13.6px

  --line-height-title: 1.333333;
  --line-height-base: 1.25;

  // --------------------------
  // LAYOUT
  // --------------------------
  --main-header-height: 80px;
  --page-section-width: 100vw;
  --page-section-container-width: 1440px;
  --page-section-inner-padding-left: 116px;
  --page-section-inner-padding-right: 60px;

  // --------------------------
  // SPACINGS
  // --------------------------
  --spacing-base-value: 8px;
  --spacing-10x: calc(10 * var(--spacing-base-value)); // 80px
  --spacing-9x: calc(9 * var(--spacing-base-value)); // 72px
  --spacing-8x: calc(8 * var(--spacing-base-value)); // 64px
  --spacing-7x: calc(7 * var(--spacing-base-value)); // 56px
  --spacing-6x: calc(6 * var(--spacing-base-value)); // 48px
  --spacing-5x: calc(5 * var(--spacing-base-value)); // 40px
  --spacing-4x: calc(4 * var(--spacing-base-value)); // 32px
  --spacing-3x: calc(3 * var(--spacing-base-value)); // 24px
  --spacing-2x: calc(2 * var(--spacing-base-value)); // 16px
  --spacing-1x: var(--spacing-base-value); //8px

  // --------------------------
  // OTHER
  // --------------------------
  --border-radius-xxl: 30px;
  --border-radius-xl: 24px;
  --border-radius-l: 15px;
  --border-radius-m: 7px;
  --border-radius-s: 4px;

  --box-shadow-l: 0 7px 24px 0 rgba(0, 0, 0, 0.1), 0 3px 24px -2px rgba(0, 0, 0, 0.04), 0 3px 24px 0 rgba(0, 0, 0, 0);
  --box-shadow-s: 0 3px 24px -2px rgba(0, 0, 0, 0.04), 0 3px 24px 0 rgba(0, 0, 0, 0);
}
