html {
  font-size: var(--fontsize-body-1);
}

body:not(.v2021) {
  * {
    box-sizing: border-box;
  }

  *:not([class*="ds-"]):not([class*="mat-"]):not([class*="leaflet-"]):not([class*="tc-privacy"]):not(
      [class*="iti_"]
    ):not([class*="startup__"]):not([class*="btn-switch"]):not([class*="span-switch"]):not([class*="footer_"]):not(
      [class*="transparent"]
    ):not([class*="log-out"]):not([class*="pagination_"]):not([class*="btn-switch"]):not([class*="span-switch"]):not(
      [class*="footer_"]
    ):not([class*="transparent"]):not([class*="log-out"]):not([class*="popup_"]):not([class*="strong-title"]):not(
      [class*="customised"]
    ):not([class*="dashboard"]):not([class*="apexcharts"]):not(foreignObject):not(th):not(td) {
    background-color: var(--color-bg, transparent);
    color: var(--color-text, inherit);
  }

  h1,
  h2,
  h3,
  h4:not([class*="sidebar_"]),
  h5,
  h6,
  p {
    margin: var(--spacing-s) 0;
    line-height: 1.2;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  img,
  video {
    max-width: 100%;
    height: auto;
  }

  h1 {
    font-size: var(--fontsize-title-1);
  }

  h2 {
    font-size: var(--fontsize-title-2);
  }

  h3 {
    font-size: var(--fontsize-title-3);
  }

  h4 {
    font-size: var(--fontsize-title-4);
  }

  a.ds-btn {
    display: inline-block;
  }
}

/* New form style ------- */

.input-v2 {
  border-radius: 4px;
  border: 1px solid #395179;
  background: #fff;
  padding: 10px 16px;
  color: #001d4a;
  margin-top: 8px;
  height: 30px;
}

.input-label-v2 {
  color: #001d4a;
  font-weight: 500;
  line-height: 150%;
}

.ds-input-group--error .input-v2 {
  color: #be0e25;
  border-color: #be0e25;
  background-color: #fef8f8;
  margin-bottom: 8px;
}

.ds-input-group--error {
  color: #be0e25;
}
