body:not(.v2021) {
// --------------------------
  // Fil d'ariane
  // --------------------------
  .breadcrumb {
    margin-bottom: var(--spacing-l);
  }

  .breadcrumb__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: var(--fontsize-body-2);
  }

  .breadcrumb__item {
    display: flex;

    &:not(:last-child) {
      margin-right: 8px;
      opacity: 0.5;

      &::after {
        margin-left: 8px;
        content: "/";
      }
    }

    a,
    a:visited {
      text-decoration: none;
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
