// --------------------------
// Contenu éditorial
// --------------------------
.article {
  h2 {
    position: relative;
    max-width: 43.5rem;
    padding-bottom: 10px;

    &,
    &:first-child {
      margin: 56px 0 32px;
    }

    &::after {
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      max-width: 120px;
      border: solid 2px #ffcd00;
      border-radius: 2px;
      background-color: #ffcd00;
      content: "";
    }
  }

  h3,
  h3:first-child {
    margin: 32px 0;
  }

  p,
  p:first-child {
    margin: 1em 0 !important;
  }

  strong {
    font-weight: bold;
  }

  ul,
  ol {
    padding-left: 3ch;
  }

  li::marker {
    color: var(--jaune);
    font-weight: bold;
  }

  .article__section:first-child h2:first-child,
  .article__section:first-child h3:first-child,
  .article__section:first-child p:first-child {
    margin-top: 0;
  }

  .article__section .ds-btn {
    margin-top: 32px;
  }
}

.change-margin {
  margin: 0;
}