html, body {
  scroll-behavior: smooth;
}

.v2021 {
  font-size: var(--font-size-body);
  line-height: var(--line-height-base);
  color: var(--text-color-body);

  * {
    box-sizing: border-box;
  }

  // *:not([class*="ds-"]) {
  //   background-color: unset;
  //   color: unset;
  // }

  h1, h2, h3 {
    margin-top: 0;
    line-height: var(--line-height-title);

    &::after {
      content: none;
    }
  }

  h1 {
    display: block;
    font-size: var(--font-size-title-1);
  }

  h2 {
    font-size: var(--font-size-title-2);
  }

  h3 {
    font-size: var(--font-size-title-3);
  }

  p:not([class*="legend_"]):not([class*="popup_"]) {
    line-height: var(--line-height-base);
  }

  a:not([class]),
  a:not([class]):visited,
  a:not([class]):hover,
  a:not([class]):focus {
    color: inherit;
    text-decoration: none;
  }

  img:not([class*="legend_"]):not([class*="structure__image"]):not([class*=btn-close]):not([class*="startup_logo"]):not([class*=card__]):not([class*=tandem_img]):not([class*=quote__image]):not([class*=plan-deeptech__icons]):not([class*=about-us__logo]):not([class*=logos__logo]):not([class*=arrow_up]):not([class*=arrow_back]):not([class*=startup__imag]):not([class*=sidebar_logo]):not([class*=customised]),
  video {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  video {
    display: block;
  }

  button {
    cursor: pointer;
  }

  :focus:not(:focus-visible) {
    outline: none
  }
}

.link {
  position: relative;
  z-index: 0;
  display: inline-flex;
  align-items: center;
  text-decoration: none;

  &,
  &:visited {
    color: inherit;
  }

  &::after,
  &::after {
    position: absolute;
    bottom: 0.25em;
    left: -2px;
    right: -2px;
    height: 6px;
    background: transparent;
    content: '';
    z-index: -1;
    transition: background ease-out 0.1s;
  }

  &:hover::after,
  &:focus::after {
    background: var(--interaction-color-light);
    transition: background ease-in 0.1s;
  }

  .icon {
    font-size: 0.875em;
  }

  .icon:first-child {
    margin-right: 0.25rem;
  }

  .icon:not(:first-child) {
    margin-left: 0.25rem;
  }
}

.breadcrumb__item {
  a,
  a:hover,
  a:focus {
    text-decoration: none;
  }
}
