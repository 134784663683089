/* You can add global styles to this file, and also import other style files */
// --------------------------
// Imports for version 1
// --------------------------
@import "assets/styles/variables";
@import "assets/styles/layout";
@import "assets/styles/ds-override";
@import "assets/styles/material-override";
@import "assets/styles/carousel";
@import "assets/styles/breadcrumb";
@import "assets/styles/editorial-content";
@import "assets/styles/shame";
@import "assets/styles/globals";

// --------------------------
// Imports for the redesign 2021
// --------------------------
@import "assets/styles/redesign-v2021/variables";
@import "assets/styles/redesign-v2021/reset";
@import "assets/styles/redesign-v2021/layout";
@import "assets/styles/redesign-v2021/helpers";
@import "assets/styles/redesign-v2021/globals";