// --------------------------
// Carousel (css supplémentaire)
// --------------------------
.swiper-slide {
  h2 {
    margin-bottom: 56px;
    font-size: 2.5rem;
  }

  p {
    margin-bottom: 32px;
    color: var(--bleu);
  }

  .ds-btn {
    margin-left: auto;
  }

  @media screen and (max-width: 1024px) {
    h2 {
      margin-bottom: 32px;
    }
  }

  @media screen and (max-width: 599px) {
    h2 {
      font-size: 2rem;
    }
  }
}
